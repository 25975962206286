import { Query } from 'react-apollo';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { metaDescription, metaKeywords } from 'constants/meta';
import newsQuery from 'graphql/queries/news.graphql';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import MainTemplate from 'components/templates/MainTemplate';
import TextFormatter from 'components/atoms/TextFormatter';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import ItemsList from 'components/molecules/ItemsList';

const Title = styled.h1`
  margin-top: 0.3em;
  margin-bottom: 0.7rem;
  font-weight: normal;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.neutral3};
  display: block;
`;

const ItemsWrapper = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
`;

const NewsPage = ({ match }) => (
  <Query
    query={newsQuery}
    variables={{
      id: match.params.id,
    }}
  >
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingPage />;
      }

      if (error) {
        return <ErrorLoadingPage />;
      }

      if (!data.news) {
        return <NotFoundPage />;
      }

      const { news } = data;

      return (
        <MainTemplate>
          <Helmet>
            <title>{news.name}</title>
            <meta name="keywords" content={metaKeywords.newses} />
            <meta name="description" content={metaDescription.news(news)} />
          </Helmet>
          <Breadcrumbs
            links={[
              {
                link: '/news',
                text: 'Новости',
              },
            ]}
          />
          <Title>{news.name}</Title>
          <Subtitle>{dayjs(news.createdAt).format('DD MMM YYYY')}</Subtitle>
          <TextFormatter text={news.content} />
          {news.items.length > 0 && (
            <ItemsWrapper>
              <ItemsList cols={[2, 3, 4]} items={news.items.map((item) => item.item)} />
            </ItemsWrapper>
          )}
        </MainTemplate>
      );
    }}
  </Query>
);

export default NewsPage;
